import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import type { Option } from 'shared/types'
import { useQuery } from 'context/QueryProvider'
import { useI18n } from 'context/I18nProvider'
import { API_FILTERS } from 'constants/api-v2'

export type Value = Option | string | number | boolean | null | undefined
export interface FilterValues {
  [key: string]: Value | Value[]
  q?: string
}

const getValue = (value: Value | null | undefined): string => {
  if (value === null || value === undefined) {
    return ''
  }

  if (typeof value === 'object' && 'value' in value) {
    return value.value ?? ''
  }

  return value.toString()
}

const updateSearchParams = (searchParams: URLSearchParams, values: FilterValues) => {
  Object.entries(values).forEach(([key, value]) => {
    searchParams.delete(key)
    searchParams.delete(`${key}[]`)

    if (Array.isArray(value)) {
      value.forEach(val => {
        searchParams.append(`${key}[]`, getValue(val).toString())
      })
      // Skip if it has no value (multi-button can have "true", "false" or null)
    } else if (!!value) searchParams.set(key, getValue(value).toString())
  })
}

const valuesToURLSearchParams = (values?: FilterValues) => {
  const newParams = new URLSearchParams()

  updateSearchParams(newParams, values ?? {})

  return newParams
}

const useFilters = (
  prefix: string,
  updateHistory: boolean = true,
  initialValues: FilterValues = {},
  callback: Function = () => {},
) => {
  const { client } = useQuery()
  const { i18n, i18name } = useI18n()
  const [searchParams] = useSearchParams()
  const [isInitialized, setInitialized] = React.useState(false)
  const [values, setValues] = React.useState<{ [prefix: string]: FilterValues }>({
    [prefix]: initialValues,
  })

  /**
   * Performance improvement :
   * Update `url` without navigation as we only want our state to update for various fetch inside the app.
   * This happens each time `values` change so that there is only one data source.
   * @see https://github.com/kentcdodds/kentcdodds.com/blob/main/app/utils/misc.tsx#L296-L323
   */
  React.useEffect(() => {
    function updateUrl() {
      const params = valuesToURLSearchParams(values?.[prefix]).toString()
      const newUrl = [window.location.pathname, params]
        .filter(Boolean)
        .join('?')

      window.history.replaceState(null, '', newUrl)
    }

    if (updateHistory && !!values && prefix in values) updateUrl()

    return () => {}
  }, [prefix, updateHistory, values])

  const fetchLabels = React.useCallback(async () => {
    async function init() {
      // For tests
      if (!client) return

      const params = searchParams.toString()
      const updatedValues: FilterValues = {}

      const response = await client.get(`${API_FILTERS}?${params}`)

      searchParams.forEach((value: string, keyString: string) => {
        const key = keyString.replace('[]', '')

        if (key in response) {
          updatedValues[key] = response[key].map((option: any) => ({
            label: option.name ?? i18name(option) ?? option.fullName,
            value: option.id,
          }))
        } else if (key === 'status') {
          // Special case for 'status' filters
          updatedValues.status = [
            // @ts-ignore
            ...('status' in updatedValues ? updatedValues.status : []),
            {
              label: i18n(value),
              value,
            },
          ]
        } else {
          // All "hardcoded" multi-buttons filters
          updatedValues[key] = value
        }
      })
      setValues(prev => ({ ...prev, [prefix]: { ...(prev?.[prefix] ?? {}), ...updatedValues } }))
    }

    init()

    return () => {}
  }, [client, searchParams, i18n, i18name, prefix])

  // Init
  React.useEffect(() => {
    if (!isInitialized) {
      fetchLabels()
      setInitialized(true)
    }

    return () => {}
  }, [fetchLabels, initialValues, isInitialized, updateHistory, searchParams])

  const updateValue = React.useCallback((key: string | FilterValues, value?: Value | Value[]) => {
    if (typeof key === 'string') {
      setValues(prev => ({ ...prev, [prefix]: { ...(prev?.[prefix] ?? {}), [key]: value } }))
    } else {
      setValues(prev => ({ ...prev, [prefix]: { ...(prev?.[prefix] ?? {}), ...key } }))
    }

    callback?.()
  }, [prefix, callback])

  const reset = React.useCallback((filters?: FilterValues) => {
    const newValues = filters ?? initialValues

    setValues({ [prefix]: newValues })
    callback()
  }, [initialValues, prefix, callback])

  const getDependencyUrl = React.useCallback((dependencyKey: string, outputKey: string) => {
    const dependentValues = values?.[prefix][dependencyKey] ?? []
    const params = new URLSearchParams()

    // optimize with updateSearchParams
    if (Array.isArray(dependentValues)) {
      dependentValues.forEach(val => {
        params.append(`${outputKey}[]`, getValue(val).toString())
      })
    } else {
      params.set(outputKey, getValue(dependentValues).toString())
    }

    return params.toString()
  }, [values, prefix])

  const url = React.useMemo(() => {
    const params = new URLSearchParams()

    updateSearchParams(params, values?.[prefix] ?? {})

    return params.toString()
  }, [values, prefix])

  const getCount = React.useCallback((keys: string[]) => (
    keys.reduce((acc, key) => {
      const item = values?.[prefix][key]

      if (Array.isArray(item)) {
        return acc + item.length
      }

      return acc + (Number(key in (values?.[prefix] ?? {})) || 0)
    }, 0)
  ), [values])

  return {
    values: values?.[prefix] ?? {},
    setValue: updateValue,
    url,
    reset,
    getDependencyUrl,
    getCount,
    isInitialized,
  }
}

export default useFilters
